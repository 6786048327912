import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "introduce",
		component: () => import("../views/Introduce.vue"),
	},
	{
		path: "/write",
		name: "write",
		component: () => import("../views/Write.vue"),
	},
	{
		path: "/complete",
		name: "complete",
		component: () => import("../views/Complete.vue"),
	},
	{
		path: "/image",
		name: "image",
		component: () => import("../views/ImageDownload.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export default router;
